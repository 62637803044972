@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Montserrat:wght@900&family=Poppins&family=Roboto&display=swap');

.App {
	width: 100vw;
	min-height: 100vh;
	overflow-x: none;
}

.page {
	width: 100vw;
	display: flex;
	flex-direction: column;
}

.text,
.title,
.page-title,
.help-text,
li,
h2 {
	font-family: 'Kanit', sans-serif;
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

#home {
	background-size: cover;
	background-image: url('./images/our-work-bg4.jpg');
	background-repeat: no-repeat;
	background-position: 50% 0%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	min-height: max(100vh, 800px);
}

#our-work-page-wrapper {
	background: white;
	transform: translateY(-100px) skewY(176deg);
}

#our-work-page {
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
	z-index: 0;
	transform: skewY(-176deg);
	padding: 100px 0 100px;
}

#our-work-page h2,
#our-work-page .text {
	/* padding-bottom: 20vh; */
	transform: translateY(-30vh);
	color: rgb(40, 40, 40);
	z-index: 2;
}

#bring-to-table-page-wrapper {
	position: relative;
	background: #00255b;
	transform: skewY(-176deg);
	z-index: 3;
}

#bring-to-table-page {
	position: relative;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	position: relative;
	gap: 0;
	background: transparent;
	min-height: none;
	z-index: 0;
	transform: skewY(176deg);
	padding: 100px 0 100px;
}

#bring-to-table-page > h2,
#bring-to-table-page > ul {
	position: relative;
	color: white;
	z-index: 5;
}

#inclusive-hiring-practices-page {
	min-height: 100vh;
	background-size: cover;
	background-image: url('./images/inclusive-bg-1.jpg');
	background-repeat: no-repeat;
	background-position: 10% 0%;
	justify-content: space-evenly;
	align-items: flex-end;
	flex-direction: row;
}

#inclusive-hiring-practices-page > h2,
#inclusive-hiring-practices-page > .text {
	margin-bottom: 5vh;
	color: white;
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

.title {
	animation: fade-in-up 1.25s;
	text-align: center;
	max-width: min(600px, 70vw);
	font-size: clamp(24px, 7.5vw, 64px);
	font-weight: normal;
	/* text-transform: uppercase; */
}

.sub-title {
	animation: fade-in-up 1.25s;
	text-align: center;
	max-width: min(500px, 65vw);
	font-size: clamp(16px, 4vw, 22px);
	font-weight: normal;
}

.help-text {
	color: rgba(0, 0, 0, 0.671);
	font-size: 18px;
	text-transform: uppercase;
	margin-top: 10vh;
	opacity: 0;
	animation: fade-in-up 1.25s 1.5s forwards;
}

.title,
.sub-title,
.help-text {
	color: white;
	margin-left: 5vw;
	text-align: left;
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

h2 {
	font-size: clamp(24px, 7.5vw, 64px);
	opacity: 0;
	max-width: min(450px, 50vw);
}

.text {
	max-width: min(70vw, 600px);
	font-size: clamp(16px, 4vw, 22px);
	line-height: 1.75;
	opacity: 0;
}

.text-reveal-animation {
	animation: fade-in-up 1s 0.2s forwards cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

header {
	background: transparent;
	width: 90vw;
	padding: 20px 5vw 20px 5vw;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

header > img {
	height: clamp(24px, 7.5vw, 64px);
	z-index: 10;
}

button:hover {
	cursor: pointer;
}

/* ####################################################################### */
/* ############################# CONTACT BAR ############################# */
/* ####################################################################### */

.contact-bar {
	padding: 10vh 0 10vh;
	background: #00255b;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15vw;
	font-family: 'Poppins', sans-serif;
}

.contact-bar-title {
	font-size: 24px;
	text-transform: uppercase;
}

.contact-info-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.contact-option {
	color: white;
}

.contact-option > i {
	margin-right: 20px;
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

ul {
	list-style: none;
	z-index: 0;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 50px;
}

li {
	font-size: clamp(16px, 4vw, 22px);
	max-width: min(400px, 80vw);
	text-align: start;
	max-width: 300px;
	opacity: 0;
}

a {
	color: white;
	font-weight: bold;
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

@media screen and (max-width: 1375px) {
	ul {
		grid-template-columns: 1fr 1fr;
		gap: 100px;
	}
}

@media screen and (max-width: 1100px) {
	#home {
		align-items: flex-start;
	}

	.title,
	.sub-title,
	.help-text {
		margin-left: 5vw;
		text-align: left;
	}

	ul {
		gap: 50px;
	}

	#inclusive-hiring-practices-page {
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
	}

	#inclusive-hiring-practices-page > h2,
	#inclusive-hiring-practices-page > .text {
		margin-left: 5vw;
	}

	#inclusive-hiring-practices-page > h2 {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 900px) {
	#our-work-page {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: left;
	}

	#our-work-page h2 {
		padding-bottom: 0;
	}

	ul {
		transform: translateX(-20px);
	}
}

@media screen and (max-width: 550px) {
	#bring-to-table-page,
	#our-work-page {
		padding: 50px 0 50px;
	}

	.contact-bar {
		flex-direction: column;
		gap: 20px;
	}

	.help-text {
		font-size: 14px;
	}

	#bring-to-table-page {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 30px;
	}

	#bring-to-table-page > h2,
	#bring-to-table-page .text {
		margin-bottom: 0;
	}

	.text {
		max-width: 85vw;
	}
}

/* ####################################################################### */
/* ####################################################################### */
/* ####################################################################### */

@keyframes fade-in-up {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	80% {
		transform: translateY(0);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
